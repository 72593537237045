import React from 'react'
import SEO from '../components/seo'
import Layout from '../layout'
import Header from '../components/header'
import Container from '../components/container'

const NotFoundPage = () => (
  <Layout fullHeight>
    <SEO title='404: Not found' />
    <Header />

    <Container noPadding>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
